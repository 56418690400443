import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import { Grid } from "@mui/material";
import { GetDataKotaByid } from "../tables/kotaServices";
import { GetDataLaporanByid } from "../tables/laporanServices";
import LaporanFormEdit from "./element/LaporanFormEdit";

const LaporanFormPageEdit = () => {
  const { laporanId } = useParams(); 
  const [laporanData, setLaporanData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetDataLaporanByid(laporanId)
        setLaporanData(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [laporanId]);

  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {laporanData !== null && <LaporanFormEdit laporan={laporanData} />}
      </Grid>
    </Grid>
  );
};

export default LaporanFormPageEdit;
