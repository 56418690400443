import axios from 'axios';

const GetDataIzinByid = async (id) => {
  try {

    const url = 'https://services.asset.binercloud.com/index.php/kota/getbyid';


    const response = await axios.post(url, {id},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const GetDataIzin = async (keyword, status,tanggal_awal,tanggal_akhir) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/presensi/get';

    const response = await axios.post(url, {keyword,status,tanggal_awal,tanggal_akhir},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const EditIzin = async (data) => {
  try {

    const url = 'https://services.asset.binercloud.com/index.php/presensi/approve';


    const response = await axios.post(url, data,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(!response.data.status){
      throw "Gagal Mengganti Data";
    }
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
  };
  

export {GetDataIzin, GetDataIzinByid, EditIzin};
