import Swal from "sweetalert2";

const notifError = (msg) => {
    const toast = Swal.mixin({
        toast: true,
        position:"center",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        color: "#ffffff",
        background: "#EA4654",
        icon: "error",
        cancelButtonColor: "#ffffff",
     
    });
    toast.fire({
        title: msg,
    });
};

const notifWarning = (msg) => {
    const toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        color: "#ffffff",
        background: "#f09000",
        icon: "error",
        cancelButtonColor: "#ffffff",
        customClass: {
            container: 'custom-swal-container', // Add a custom class for styling
        },
        
    });

    toast.fire({
        title: msg,
    });
};


const notifSucces = (msg) => {
    const toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        showCloseButton: true,
        color: "#ffffff",
        background: "#28a745",
        icon: "success",
        cancelButtonColor: "#ffffff",
       
    });
    toast.fire({
        title: msg,
    });
};

export { notifError, notifSucces, notifWarning };
