import axios from 'axios';



const GetDatalokasiByid = async (id) => {
    try {
      const url = 'https://services.asset.binercloud.com/index.php/datalokasi/getbyid';


      const response = await axios.post(url, {id},{
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if(!response.data.status){
        throw "Gagal";
      }
      return response.data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
}; 

const GetDatalokasi = async (kota,status) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalokasi/get';
    const response = await axios.post(url,{
      "id_kota":kota, status
  }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log("RES", response)
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const GetDatalokasiAll = async () => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalokasi/get';
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(!response.data.status){
      throw "Gagal Menambah data"
    }
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error;  
  }
};
const CreateLokasiBulk = async (data) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalokasi/createbulk';
    const response = await axios.post(url, {lokasi_data:data},{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(!response.data.status){
      throw "Gagal Menambah data"
    }
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error;  
  }
};

const Createlokasi = async (nama, latitude, longitude, kota, image, gedung) => {
  try {
    
    if(image){
      const urlUpload = 'https://services.asset.binercloud.com/index.php/upload/lokasiimage';

      const formData = new FormData();
      formData.append('file', image);
      const responseUpload = await axios.post(urlUpload, formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });

    if (!responseUpload.data.status) {
      throw responseUpload.data.message;
    }
    const url = 'https://services.asset.binercloud.com/index.php/datalokasi/create';
  
    const response = await axios.post(url, {  
              nama,
              longitude,
              latitude,
              'alamat':gedung,
              kota,
              url_image:responseUpload.data.data.url
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data; 
  } else{
    const url = 'https://services.asset.binercloud.com/index.php/datalokasi/create';
     await axios.post(url, {
              nama,
              longitude,
              latitude,
              'alamat':gedung,
              kota,
              url_image:""          
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }

}

const Deletelokasi = async (id) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalokasi/delete';
    const response = await axios.post(url, {id},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if(!response.data.status){
       throw "GAGAL";
    }
  } catch (error) {
    console.error('Error updating data:', error.message);
    throw error;
  }  
  };

const Editlokasi = async (id, nama, longitude, latitude, kota, image, gedung, oldImage) => {
    try {
      var url_image='';
        if (image) {
          const urlUpload = 'https://services.asset.binercloud.com/index.php/upload/lokasiimage';
          
          // Create FormData and append the file
          const formData = new FormData();
          formData.append('file', image);
          const responseUpload = await axios.post(urlUpload, formData, {
            headers: {
              'Content-Type': `multipart/form-data;`,
            },
          });
          
              if (!responseUpload.data.status) {
                throw responseUpload.data.message;
              }
              url_image= responseUpload.data.data.url;
            
        }
      
      if (oldImage) {
       
  
        const url = 'https://services.asset.binercloud.com/index.php/datalokasi/edit';
    const response = await axios.post(url, {  
      id,
              nama,
              longitude,
              latitude,
              'alamat':gedung,
              kota,
              url_image
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if(!response.data.status){
      throw "Gagal Menambah data"
    }
    return response.data; 
      } else {
        console.log("Insert")

        const url = 'https://services.asset.binercloud.com/index.php/datalokasi/edit';
        const response = await axios.post(url, { 
                  id, 
                  nama,
                  longitude,
                  latitude,
                  'alamat':gedung,
                  kota,
                  url_image
        },{
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        if(!response.data.status){
          throw "Gagal Menambah data"
        }
        return response.data; 
  
      }
    } catch (error) {
      console.error('Error updating location:', error.message);
      throw error;
    }
  };

  const ApproveLokasi = async (data) => {
    try {
  
      const url = 'https://services.asset.binercloud.com/index.php/datalokasi/approve';
  
  
      const response = await axios.post(url, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if(!response.data.status){
        throw "Gagal Mengganti Data";
      }
      return response.data; 
  
    } catch (error) {
      console.error('Error while fetching data:', error.message);
      throw error; 
    }
    };
export {GetDatalokasi, GetDatalokasiByid, Createlokasi,Editlokasi, Deletelokasi,GetDatalokasiAll,CreateLokasiBulk, ApproveLokasi}
