import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { DeleteUser } from "../userServices";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";
import { UpdateAkses } from "../roleServices";

const Roledata = ({ aksess, loading, onDeleteEvent }) => {
  const handleEditClick = (eventId) => {
    window.location = `#/form-layouts/edit-form-event/${eventId}`;
  };

  const handleDeleteClick = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this User?")) {
      try {
        await DeleteUser(eventId);
        await notifSucces("User Berhasil Di Hapus");
        onDeleteEvent();
      } catch (error) {
        console.error("Error while deleting User:", error);
        notifWarning("User Gagal Di Hapus");
      }
    }
  };

  const handleCheckboxChange = async (aksesId, field, newValue) => {
    try {
      console.log(`Field ${field}  ${aksesId} updated successfully!`);
      await UpdateAkses({
        "id":parseInt(aksesId),
    "column":field,
    "value": newValue
      });
      
    } catch (error) {
      console.error(`Error updating field ${field}:`, error);
      notifWarning("Data Gagal Disimpan");
    }
  };
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        wordWrap: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        overflowX: "auto",
        maxWidth: "100%",
      }}
    >
      <Table
        aria-label="simple table"
        sx={{
          mt: 3,
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                No
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Role
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Nama Menu
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Akses Create
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Akses Update
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Akses Delete
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Akses Read
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                Akses Export
              </Typography>
            </TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {aksess.map((event, index) => (
            <TableRow key={event.akses_id}>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {index + 1}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="h6"
                  sx={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  {event.role}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="h6"
                  sx={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  {event.nama_menu}
                </Typography>
              </TableCell>
              <TableCell>
                <input
                  type="checkbox"
                  defaultChecked={event.aksesCreate === "1"}
                  onChange={(e) =>
                    handleCheckboxChange(event.akses_id, "aksesCreate", e.target.checked)
                  }
                  
                />
              </TableCell>
              <TableCell>
                <input
                  type="checkbox"
                  defaultChecked={event.aksesUpdate === "1"}
                  onChange={(e) =>
                    handleCheckboxChange(event.akses_id, "aksesUpdate", e.target.checked)
                  }
                  
                />
              </TableCell>
              <TableCell>
                <input
                  type="checkbox"
                  defaultChecked={event.aksesDelete === "1"}
                  onChange={(e) =>
                    handleCheckboxChange(event.akses_id, "aksesDelete", e.target.checked)
                  }
                  
                />
              </TableCell>
              <TableCell>
                <input
                  type="checkbox"
                  defaultChecked={event.aksesRead === "1"}
                  onChange={(e) =>
                    handleCheckboxChange(event.akses_id, "aksesRead", e.target.checked)
                  }
                  
                />
              </TableCell>
              <TableCell>
                <input
                  type="checkbox"
                  defaultChecked={event.aksesExport === "1"}
                  onChange={(e) =>
                    handleCheckboxChange(event.akses_id, "aksesExport", e.target.checked)
                  }
                  
                />
              </TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Roledata;
