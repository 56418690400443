import React, { useEffect, useState } from "react";
import { Card, CardContent, Box, Typography, Button, TextField, Pagination } from "@mui/material";
import ListAlarmTabel from './element/AlarmTable';
import { GetDataalarm } from "./alarmServices";

const AlarmTabel = () => {
  const [alarm, setalarm] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading,setIsLoading]= useState(false);

  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }
  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchDataAlarm(page,search);
    }, 500);

    return () => clearTimeout(delayTimeout); 
  }, [page, search]);

  const fetchDataAlarm= async (p,s) => {
    try {
      const response = await  GetDataalarm();
      if(response.status){
      setalarm(response?.data??[]);
      }else{
        setalarm([])
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news data:", error);
      setIsLoading(false);
    }
  };
  const handleSearchChange = (User) => {
    setSearch(User.target.value);
    setPage(1);
  };

  return (
        <Box>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Data Alarm</Typography>
             
            </CardContent>
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            maxWidth: 300, 
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small" 
            value={search}
            onChange={handleSearchChange}
            fullWidth 
          />
        </Box>
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <ListAlarmTabel users={alarm} loading={isLoading}/>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Pagination
                count={totalPages} 
                page={page}
                onChange={(User, page) => setPage(page)}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Card>
      </Box>
  );
};

export default AlarmTabel;
