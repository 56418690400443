import axios from 'axios';



const handleLogin = async (email, password) => {
  try {
    const response = await axios.post('https://services.asset.binercloud.com/index.php/auth/login', {
      username: email,
      password: password,
    });


    const { data, status, message } = response.data;

    if (!status) {
      throw response.data;
    }

    console.log(data)
    const token = data.id;
    const user = data.username;
    const role = data.role;
    if(role !== 'user'){
    await localStorage.setItem('authToken', token);
    await localStorage.setItem('userData', user); 
    await localStorage.setItem('role', role); 
    }

   return data

  } catch (status) {
    return status
    // console.status('status while logging in:', status.message);
    // throw status
  }
};
const handleLogOut = async () => {
    try {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("role");
        window.location = '/';
      } catch (status) {
        console.status('status while logging in:', status.message);
      } 
};

export { handleLogin, handleLogOut };
