import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress
} from "@mui/material";
import { ApproveLokasi, Deletelokasi } from "../lokasiServices";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";


  
const lokasiTabel = ({topics,loading, onDeleteDiscus}) => {

  const handleApproveClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENGAPPROVE Lokasi INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "Approve"
  }
  try{
   await ApproveLokasi(data);
   notifSucces("Lokasi Berhasil Di Approve");
   onDeleteDiscus();
  }catch(e){
    notifWarning("Lokasi Gagal Di Approve");
  }}
  };

  const handleAjukanDeleteClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENDELETE Lokasi INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "Pending Hapus"
  }
  try{
   await ApproveLokasi(data);
   notifSucces("Lokasi Berhasil Di Approve");
   onDeleteDiscus();
  }catch(e){
    notifWarning("Lokasi Gagal Di Approve");
  }}
  };

  const handleTolakClick = async (id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENOLAK Lokasi INI?")) {
      var data = {
        "id": parseInt(id),
        "status_approval": "Ditolak"
    }
    try{
     await ApproveLokasi(data);
     notifSucces("Lokasi Berhasil Di Tolak");
     onDeleteDiscus();
    }catch(e){
      notifWarning("Lokasi Gagal Di Tolak");
    }
    }
  };

  var userRole = localStorage.getItem("role")??"viewer";

  const handleEditClick = (topicId) => {
    window.location=`#/form-layouts/edit-form-discus/${topicId}`;
  };

  const handleDeleteClick = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this Lokasi?")) {
      try {
        await Deletelokasi(eventId);
       await notifSucces("Lokasi Berhasil Di Hapus");
        onDeleteDiscus();
      } catch (error) {
        if (error.code === '23503') {
          notifWarning("Mohon Hapus Data Alarm untuk lokasi Ini");
        }else{
          notifWarning("Kota Gagal Di Hapus");
        }

        console.error("Error while deleting User:", error);
        
      }
    }
  };
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (topics.length === 0 || topics == null || !topics) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
        sx={{
          wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal', 
          overflowX: "auto",
          maxWidth: "100%", 
        }}
      >
    <Table
    aria-label="simple table"
    sx={{
       wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
      mt: 3,
    }}
  >
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            No
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            nama Asset
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            kota
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            image
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            longitude
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            latitude
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Alamat
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            status
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            aksi
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {topics.map((topic,index) => (
        <TableRow key={topic.title}>
          <TableCell>
            <Typography
              sx={{
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
             {index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                    fontWeight: "600",
                  }}
                >
                  {topic.nama}
                </Typography>
               
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              
                <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.nama_kota}
                </Typography>
               
              </Box>
          </TableCell>
          <TableCell>
            <img
              src={topic.url_image}
              alt={topic.nama} 
              style={{ maxWidth: '100px', maxHeight: '100px' }} 
            />
          </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic['longitude']}
                </Typography>
          </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                    fontWeight: "600",
                  }}
                >
                  {topic['latitude']}
                </Typography>
          </TableCell>
          
          <TableCell>
          <Typography
                  
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                    fontWeight: "400",
                    width:"50"
                  }}
                >
                  {topic.alamat}
                </Typography>
          </TableCell>

          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.status_approval}
                </Typography>
          </TableCell>
          
          <TableCell align="center">
          {userRole === 'approver'  && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled = {!(topic.status_approval === "Pending" || topic.status_approval === "Pending Hapus")}
                      onClick={() =>  topic.status_approval === "Pending Hapus"? handleDeleteClick(topic.id): handleApproveClick(topic.id) }
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled = {!(topic.status_approval === "Pending" || topic.status_approval === "Pending Hapus")}
                      sx={{ ml: 2 }}
                      onClick={() => handleTolakClick(topic.id)}
                    >
                      Reject
                    </Button>
                  </>
                )}
           
           {(userRole === 'super' || userRole === 'maker' ) && ( 
           <>
           <Button
                variant="outlined"
                color="error"
                onClick={() => handleAjukanDeleteClick(topic.id)}
                sx={{
                minWidth:100, mr: 2 }}
              >
                Delete
              </Button>
              <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick(topic.id) }
            >
              Edit
            </Button>
            </>)}
              </TableCell>

        </TableRow>
      ))}
    </TableBody>
  </Table>
  </Box>
    );
  }
};

export default lokasiTabel;
