import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {baseTheme} from './assets/global/Theme-variable'
import Themeroutes from "./routes/Router";
import cors from 'cors';

const App = () => {

  const routing = useRoutes(Themeroutes);
  const theme = baseTheme;
  cors();
  
  return (
    <ThemeProvider theme={theme}>
      {routing}
    </ThemeProvider>
  );
};


export default App;
