import React, { useEffect, useState } from "react";

import { Card, CardContent, Box, Typography, Button, Pagination, Select, MenuItem} from "@mui/material";

import { GetdataRole,  } from "./kotaServices.js";
import * as XLSX from 'xlsx';
import { GetDataAkses, GetDataRole } from "./roleServices.js";
import Roledata from "./element/roleTabel.js";

const RoleTabel = () => {
  const [AksesData, setAksesData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading,setIsLoading]= useState(false);
  const [isdelete, setIsdelete]= useState(false);
  const [dataRole, setdataRole] = useState([]);
  const [Role, setRole] = useState("");

  


  const exportToExcel = () => {
    const formattedData = AksesData.map(item => ({
      'Nama Lokasi': item.nama || '',
      'Nama Kota': item.nama_kota || '',
      'Url_image': item.url_image || '',
      'Latitude': item['latitude'] || '',
      'Longitude': item['longitude'] || '',
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
  
    // Custom cell styles
    const headerCellStyle = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };
  
    const columnWidths = [
      { wch: 20 }, // Nama Petugas
      { wch: 20 }, // Lokasi
      { wch: 15 }, // Longitude
      { wch: 15 }, // Latitude
    ];
  
    // Apply custom styles to header row
    Object.keys(worksheet).forEach(cell => {
      if (cell[1] === '1') {
        worksheet[cell].s = headerCellStyle;
      }
    });
  
    worksheet['!cols'] = columnWidths;
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Data');
    XLSX.writeFile(workbook, 'laporan_data.xlsx');
  };
  const fetchRole = async () => {
    const data = await GetDataRole();
    setdataRole(data.data);
  };

  useEffect(() => {
    fetchRole();
},[]);
 
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }
  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchDiscusData(Role);
    }, 500);

    return () => clearTimeout(delayTimeout); 
  }, [isdelete,Role]);

  const fetchDiscusData = async (r) => {
    try {
      const response = await GetDataAkses(r);
  
      if (response.status) {
        setAksesData(response.data);
      }
      else{
        setAksesData([])
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news data:", error);
      setIsLoading(false);
    }
  };
  const handleSearchChange = (Topic) => {
    setSearch(Topic.target.value);
    setPage(1);
  };
  const handleDeleteDiscus=()=>{
    setIsdelete(!isdelete);
  }
  const handelAdd=()=>{
    window.location = '/#/form-layouts/form-discus'
  }
  return (
        <Box>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Data Lokasi</Typography>
              <div>
         
         </div>
            </CardContent>
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            marginRight:2
          }}
        >
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            maxWidth: 300, 
          }}
        >
          <div>
             <Select
              id="Nama-Kota"
              value={Role}
              onChange={handleRoleChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                ml:2,
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Role
              </MenuItem>
              {dataRole.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.role}
                </MenuItem>
              ))}
            </Select>
                
          </div>
        </Box>
       
        </Box>
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <Roledata aksess={AksesData} loading={isLoading} onDeleteDiscus={handleDeleteDiscus}/>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Pagination
                count={totalPages} 
                page={page}
                onChange={(event, page) => setPage(page)}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Card>

      </Box>
  );
};

export default RoleTabel;
