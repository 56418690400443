import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import SwitchCameraOutlinedIcon from '@mui/icons-material/SwitchCameraOutlined';
import SwitchLeftOutlinedIcon from '@mui/icons-material/SwitchLeftOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';

const Menuitems = [

  {
    title: "User",
    icon: SwitchCameraOutlinedIcon,
    href: "/tables/events-table",
    role: ["maker", "approver"]
  },
  {
    title: "Kota",
    icon: AlbumOutlinedIcon,
    href: "/tables/news-table",
    role: ["maker", "approver"]
  },
  {
    title: "Lokasi",
    icon: SwitchLeftOutlinedIcon,
    href: "/tables/topic-discussion-table",
    role: ["maker", "approver"]
  },
  {
    title: "Laporan",
    icon: AssignmentTurnedInOutlinedIcon,
    href: "/tables/laporan-table",
    role: ["admin","inputter", "supervisor", "viewer"]
  },
  {
    title: "alarm",
    icon: DashboardOutlinedIcon,
    href: "/tables/users-table",
    role: ["maker", "approver"]
  },
  {
    title: "Riwayat Mutasi",
    icon: AutoAwesomeMosaicOutlinedIcon,
    href: "/tables/Mutasi-table",
    role: ["maker"]
  },
  {
    title: "Perizinan",
    icon: AutoAwesomeMosaicOutlinedIcon,
    href: "/tables/Izin-table",
    role: ["supervisor", ]
  },
];

export default Menuitems;
