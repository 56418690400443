import React from "react";

import { Grid } from "@mui/material";
import LaporanForm from "./element/LaporanForm";


const LaporanFormPage = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <LaporanForm />
      </Grid>
    </Grid>
  );
};

export default LaporanFormPage;
