import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";
import { ApproveLaporan, DeleteLaporan } from "../laporanServices";



  
const LaporanTable = ({laporans,loading,onDeleteReport}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  // const [showModalDetail, setShowModalDetail] = useState(false);
  // const [selectedDetail, setSelectedDetail] = useState();

  var userRole = localStorage.getItem("role")??"viewer";
  

  const handleEditClick = (id) => {
    window.location=`#/form-layouts/edit-form-laporan/${id}`;
  };

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedImage('');
    setShowModal(false);
  };

  // const openModalDetail = (detail) => {
  //   setSelectedDetail(detail);
  //   setShowModalDetail(true);
  // };

  // const closeModalDetail = () => {
  //   setSelectedDetail(null);
  //   setShowModalDetail(false);
  // };

  const handleDeleteClick = async(id) => {
    if (window.confirm("Are you sure you want to delete this Report?")) {
      try {
        await DeleteLaporan(id);
       await notifSucces("Laporan Berhasil Di Hapus");
       onDeleteReport();
      } catch (error) {
        console.error("Error while deleting User:", error);
        notifWarning("Laporan Gagal Di Hapus");
      }
    }
  };

  const handleApproveClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENGAPPROVE LAPORAN INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "Approve"
  }
  try{
   await ApproveLaporan(data);
   notifSucces("LAPORAN Berhasil Di Approve");
   onDeleteReport();
  }catch(e){
    notifWarning("LAPORAN Gagal Di Approve");
  }}
  };
  const handleAjukanDeleteClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENGHAPUS LAPORAN INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "Pending Hapus"
  }
  try{
   await ApproveLaporan(data);
   notifSucces("LAPORAN Berhasil DI HAPUS");
   onDeleteReport();
  }catch(e){
    notifWarning("LAPORAN Gagal Di HAPUS");
  }}
  };

  const handleTolakClick = async (id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENOLAK LAPORAN INI?")) {
      var data = {
        "id": parseInt(id),
        "status_approval": "Ditolak"
    }
    try{
     await ApproveLaporan(data);
     notifSucces("LAPORAN Berhasil Di Tolak");
     onDeleteReport();
    }catch(e){
      notifWarning("LAPORAN Gagal Di Tolak");
    }
    }
  };

  
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (laporans.length === 0 || laporans == null || !laporans) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
      sx={{
         wordWrap: 'break-word', 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal', 
        // overflowX: "auto",
        overflowY:"auto",
      }}
    >
    <Table
    sx={{
      minWidth:100,
       wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
      mt: 3,
    }}
  >
     <TableContainer sx={{
      maxWidth:1250, maxHeight: 550 }}>
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            No
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            nama Petugas
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h6">
            Nama Aset
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            status
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            image
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Tanggal
          </Typography>
        </TableCell>
        
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Waktu Mulai
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Waktu Selesai
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Longitude
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Latitude
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Lokasi Start Visit
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Lokasi End Visit
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            Keterangan
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color="textSecondary" variant="h6">
            aksi
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {laporans.map((topic,index) => (
        <TableRow key={topic.title}>
          <TableCell>
            <Typography
              sx={{
                minWidth:100,
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
             {index + 1}
            </Typography>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                minWidth:100,
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                    fontWeight: "600",
                  }}
                >
                  {topic.nama}
                </Typography>
               
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                minWidth:100,
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              
                <Typography
                  variant="h6"
                  sx={{
                    minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.nama_lokasi}
                </Typography>
               
              </Box>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                minWidth:100,
                 wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                display: "flex",
                alignItems: "center",
              }}
            >
              
                <Typography
                  variant="h6"
                  sx={{
                    minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.status_approval}
                </Typography>
               
              </Box>
          </TableCell>
          <TableCell>
        <img
          src={topic.bukti_image}
          alt={topic.nama} 
          style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }} 
          onClick={() => openModal(topic.bukti_image)}
        />
      </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                    minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.tanggal}
                </Typography>
          </TableCell>
         
          <TableCell align="center">
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.start_visit.split(' ')[1].slice(0, 5) || ''}
                </Typography>
          </TableCell>
          <TableCell align="center">
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.end_visit.split(' ')[1].slice(0, 5) || ''}
                </Typography>
          </TableCell>
          <TableCell align="center">
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.longitude}
                </Typography>
          </TableCell>
          <TableCell align="center">
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.latitude}
                </Typography>
          </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:200,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.lokasi_start_visit}
                </Typography>
          </TableCell>
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:200,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.lokasi_end_visit}
                </Typography>
          </TableCell> 
          <TableCell>
          <Typography
                  variant="h6"
                  sx={{
                  minWidth:100,
                     wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
                  }}
                >
                  {topic.keterangan}
                </Typography>
          </TableCell>
          <TableCell align="center" sx={{minWidth:200}}>
          {userRole === 'supervisor'  && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled = {!(topic.status_approval === "Pending" || topic.status_approval === "Pending Hapus")}
                      onClick={() =>  topic.status_approval === "Pending Hapus"? handleDeleteClick(topic.id): handleApproveClick(topic.id) }
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled = {!(topic.status_approval === "Pending" || topic.status_approval === "Pending Hapus")}
                      sx={{ ml: 2 }}
                      onClick={() => handleTolakClick(topic.id)}
                    >
                      Reject
                    </Button>
                  </>
                )}
           
           {(userRole === 'super' || userRole === 'inputter' ) && ( 
           <>
           <Button
                variant="outlined"
                color="error"
                onClick={() => handleAjukanDeleteClick(topic.id)}
                sx={{
                minWidth:100, mr: 2 }}
              >
                Delete
              </Button>
              <Button
              variant="outlined"
              color="primary"
              // onClick={() => openModalDetail(topic) }
              onClick={() => handleEditClick(topic.id) }
            >
              Edit
            </Button>
            </>)}
          </TableCell>
        </TableRow>
        
      ))}
    </TableBody>
</TableContainer>
  </Table>
  
  <Dialog open={showModal} onClose={closeModal} maxWidth='lg'>
        <DialogContent >
          <img src={selectedImage} alt="Gambar" style={{ maxWidth: '100%' }} />
        </DialogContent>
  </Dialog>
  {/* <Dialog open={!!selectedDetail} onClose={closeModalDetail} maxWidth="lg">
  <DialogTitle>Details</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      
      <Grid item xs={6}>
        <div>
          <Typography variant="h6">Nama Petugas: {selectedDetail?.nama ?? ''}</Typography>
          <Typography variant="h6">Lokasi: {selectedDetail?.nama_lokasi ?? ''}</Typography>
          <Typography variant="h6">Start Visit : {selectedDetail?.start_visit??''}</Typography>
          <Typography variant="h6">Lokasi Start Visit :</Typography>
          <Typography variant="h6"> {selectedDetail?.lokasi_start_visit??''}</Typography>
        </div>
      </Grid>
      
      
      <Grid item xs={6}>
        <div>
          <Typography variant="h6">Longitude: {selectedDetail?.longitude ?? ''}</Typography>
          <Typography variant="h6">Latitude: {selectedDetail?.latitude ?? ''}</Typography>
          <Typography variant="h6">End Visit: {selectedDetail?.end_visit ?? ''}</Typography>
          <Typography variant="h6">Lokasi End Visit :</Typography>
          <Typography variant="h6"> {selectedDetail?.lokasi_end_visit??''}</Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
      <Typography variant="h6">Keterangan</Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography variant="h6">{selectedDetail?.keterangan??''}</Typography>
      </Grid>
  
      <Grid item xs={12}>
       
        <img src={selectedDetail?.bukti_image ?? ''} alt={selectedDetail?.nama ?? ''} style={{ maxWidth: '100%' }} />
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={closeModalDetail}>Close</Button>
  </DialogActions>
</Dialog> */}
</Box>
    );
  }
};

export default LaporanTable;
