import React, { useEffect, useState } from "react";

import { Card, CardContent, Box, Typography, Button, Pagination, Select, MenuItem} from "@mui/material";

import ListLokasiTabel from "./element/LokasiTable.js";
import { GetDatalokasi, CreateLokasiBulk } from "./lokasiServices.js";
import { GetDataKota,  } from "./kotaServices.js";
import * as XLSX from 'xlsx';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { read, utils } from "xlsx";
import { notifSucces, notifWarning } from "../../components/BaseCard/alert";

const LokasiTabel = () => {
  const [TopicData, setTopicData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading,setIsLoading]= useState(false);
  const [isdelete, setIsdelete]= useState(false);
  const [dataKota, setDataKota] = useState([]);
  const [assignment, setAssignment] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [status,setStatus] = useState("");
  let role = localStorage.getItem("role")??"viewer";
 
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedFile(null);
  };
  
  const handleUpload = async() => {
    if (!selectedFile) {
      
      handleDialogClose();
      notifWarning("Mohon Pilih File dulu");
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = async(event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(sheet, { header: 1 });
        
        var dataFix = jsonData.map(item => ({ 
          "nama": item[0],
          "longitude": item[1],
          "latitude": item[2],
          "url_image": item[3]??'',
          "kota": item[4],
          "alamat": item[5]
      })).slice(1); 
        
       const resp= await CreateLokasiBulk(dataFix);
        handleDialogClose();
        if(resp.status){
          notifSucces("Data Berhasil Disimpan");
        }else{
          notifWarning("Data Gagal Disimpan");
        }
        setIsdelete(!isdelete);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        notifWarning("Data Gagal Disimpan");
        handleDialogClose();
        setIsdelete(!isdelete);
      }
    };
  
    reader.readAsArrayBuffer(selectedFile);
  };


  const exportToExcel = () => {
    const formattedData = TopicData.map(item => ({
      'Nama Lokasi': item.nama || '',
      'Nama Kota': item.nama_kota || '',
      'Url_image': item.url_image || '',
      'Latitude': item['latitude'] || '',
      'Longitude': item['longitude'] || '',
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
  
    // Custom cell styles
    const headerCellStyle = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };
  
    const columnWidths = [
      { wch: 20 }, // Nama Petugas
      { wch: 20 }, // Lokasi
      { wch: 15 }, // Longitude
      { wch: 15 }, // Latitude
    ];
  
    // Apply custom styles to header row
    Object.keys(worksheet).forEach(cell => {
      if (cell[1] === '1') {
        worksheet[cell].s = headerCellStyle;
      }
    });
  
    // Apply column widths
    worksheet['!cols'] = columnWidths;
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Data');
    XLSX.writeFile(workbook, 'laporan_data.xlsx');
  };
  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data.data);
  };

  useEffect(() => {
    fetchKota();
},[]);
 
  const handleAssignmentChange = (event) => {
    setAssignment(event.target.value);
  };

  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }
  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchDiscusData(page,search);
    }, 500);

    return () => clearTimeout(delayTimeout); 
  }, [isdelete,assignment, role]);

  const fetchDiscusData = async () => {
    try {
      var role1 =await localStorage.getItem("role")??"viewer";
      console.log(role1,"WKWKWK", role1 === 'approver');
      var status ='';
      if(role1 === 'approver'){
        status='Pending';
      }else if(!(role1 === 'maker'||role1 === 'super')){
        status='Kosong';
      }
      const response = await GetDatalokasi(assignment,status);
  
      if (response.status) {
        setTopicData(response.data);
      }
      else{
        setTopicData([])
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news data:", error);
      setIsLoading(false);
    }
  };
  const handleSearchChange = (Topic) => {
    setSearch(Topic.target.value);
    setPage(1);
  };
  const handleDeleteDiscus=()=>{
    setIsdelete(!isdelete);
  }
  const handelAdd=()=>{
    window.location = '/#/form-layouts/form-discus'
  }
  return (
        <Box>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Data Lokasi</Typography>
              {role !== 'approver' ? (<div>
          <Button variant="outlined" style={{ marginRight: 10 }} color="primary" onClick={handelAdd} >
            Tambah Data
          </Button>
          <Button
              variant="outlined"
              color="primary"
              startIcon={<CloudUploadIcon />}
              onClick={handleDialogOpen}
            >
              Upload File
          </Button>
         </div>):null}
            </CardContent>
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            marginRight:2
          }}
        >
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            maxWidth: 300, 
          }}
        >
          <div>
            <span style={{marginLeft:20}}>Filter Kota</span>
             <Select
              id="Nama-Kota"
              value={assignment}
              onChange={handleAssignmentChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                ml:2,
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Kota
              </MenuItem>
              {dataKota.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
                
          </div>
        </Box>
        <Button variant="outlined" color="primary" sx={{paddingX:5, paddingY:1}} onClick={exportToExcel}>Export</Button>
        </Box>
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <ListLokasiTabel topics={TopicData} loading={isLoading} onDeleteDiscus={handleDeleteDiscus}/>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Pagination
                count={totalPages} 
                page={page}
                onChange={(event, page) => setPage(page)}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Card>
          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
  <DialogTitle>Upload Excel File</DialogTitle>
  <DialogContent>
    <FormControl fullWidth>
      <InputLabel htmlFor="file-input"></InputLabel>
      <Input
        type="file"
        id="file-input"
        onChange={handleFileChange}
        accept=".xlsx, .xls"
      />
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDialogClose} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleUpload} color="primary" variant="contained">
      Upload
    </Button>
  </DialogActions>
</Dialog>
      </Box>
  );
};

export default LokasiTabel;
