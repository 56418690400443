import { createClient } from '@supabase/supabase-js';
import axios from 'axios';


const GetDataAkses = async (role_id,role) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/akses/get';


    const response = await axios.post(url, {'role_id':role_id,role},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const GetDataRole = async () => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/role/get';


    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const UpdateAkses = async (data) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/akses/UpdateAkses';


    const response = await axios.post(url,data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const CreateUser = async (nama, username, password, penugasan,role) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/create';
    const user =  await localStorage.getItem("userData") ;

    const response = await axios.post(url, {
      nama,
      username,
      password,
      penugasan,
      created_by:user,
      role
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    });
   if(!response.data.status){
    // eslint-disable-next-line no-throw-literal
    throw "Gagal Membuat Data";
   }
    return response.data; 

  } catch (error) {
    console.error('Error while Create User data:', error.message);
    throw error; 
  }
  };
const CreateBulkUser = async (data) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/createbulk';

    const response = await axios.post(url, {users:data},{
      headers: {
        'Content-Type': 'application/json'
      }
    });
   if(!response.data.status){
    // eslint-disable-next-line no-throw-literal
    throw "Gagal Membuat Data";
   }
    return response.data; 

  } catch (error) {
    console.error('Error while Create User data:', error.message);
    throw error; 
  }
  };
  
const DeleteUser = async (id) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/delete';

    const response = await axios.post(url, {id},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if(!response.data.status){
       throw "GAGAL";
    }
  
  } catch (error) {
    console.error('Error updating data:', error.message);
    // Handle the error or rethrow it if needed
    throw error;
  }
  };
const EditUser = async (id, nama, username, penugasan,penugasanOld,password,role) => {
    try {
      const url = 'https://services.asset.binercloud.com/index.php/user/edit';
  
      const response = await axios.post(url, {
        id,
        nama,
        username,
        password,
        role
    },{
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if(!response.data.status){
        throw "Gagal Mengedit data"
      }
        if(penugasan!== penugasanOld){
          const url = 'https://services.asset.binercloud.com/index.php/mutasi/create';
  
          const response2 = await axios.post(url, {
            "user_id": id,
            "kota_baru": penugasan,
            "kota_lama": penugasanOld
        },{
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if(!response.data.status){
            throw "Gagal Input Mutasi data"
          }
        }
        
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
  

export { GetDataAkses, CreateUser, DeleteUser, EditUser, UpdateAkses, GetDataRole, CreateBulkUser};
