import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";
import { GetDatalokasi } from "../../tables/lokasiServices.js";
import { GetDataUser } from "../../tables/userServices.js";
import { CreateLaporan, EditLaporan } from "../../tables/laporanServices.js";
import { GetDataKota } from "../../tables/kotaServices.js";
import axios from 'axios';

const LaporanFormEdit = (laporan) => {
  const [bukti, setBukti] = useState("");
  const [dataLokasi, setDataLokasi] = useState([]);
  const [Lokasi, setLokasi] = useState("");
  const [dataKota, setDataKota] = useState([]);
  const [kota, setKota] = useState("");
  const [dataUser, setDataUser] = useState([]);
  const [user, setUser] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [image, setImage] = useState(null);
  const [keterangan, setKeterangan] = useState("");
  const [lokasiStartVisit, setLokasiStartVisit] = useState("");
const [lokasiEndVisit, setLokasiEndVisit] = useState("");
const [longitude, setLongitude] = useState("");
const [latitude, setLatitude] = useState("");

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const fetchLokasi = async (id) => {
    const data = await GetDatalokasi(id);
    if(!data.data){
      notifWarning("data Lokasi Di kota Tersebut Kosong");
    }
    await setDataLokasi(data?.data??[]);
    setLokasi(laporan.laporan.lokasi_id);
    console.log(data.data,"WKWK", laporan.laporan.lokasi_id);
  };
  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data?.data??[]);
  };
  const fetchUser = async () => {
    const data = await GetDataUser();
    setDataUser(data.data);
    console.log(data);
  };

  useEffect(() => {
    fetchKota();
    fetchUser();
    // console.log(laporan.laporan.user_id);
    const data = laporan.laporan;
    setBukti(data.bukti_image);
    setUser(data.user_id);
    setStartTime(data.start_visit);
    setEndTime(data.end_visit);
    setKeterangan(data.keterangan);
    setKota(data.kota);
    fetchLokasi(data.kota);
    setLokasiStartVisit(data?.lokasi_start_visit ?? "");
    setLokasiEndVisit(data?.lokasi_end_visit ?? "");
    setLongitude(data?.longitude ?? "");
    setLatitude(data?.latitude ?? "");
    
  }, []);

  const handleLokasiChange = (event) => {

    setLokasi(event.target.value);
  };
  const handleKotaChange = (event) => {
    fetchLokasi(event.target.value);
    setKota(event.target.value);
  };
  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handleKeteranganChange = (event) => {
    setKeterangan(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      var url_image=bukti;
          if(image){
            const urlUpload = 'https://services.asset.binercloud.com/index.php/upload/lokasiimage';
      
            const formData = new FormData();
            formData.append('file', image);
            const responseUpload = await axios.post(urlUpload, formData, {
              headers: {
                'Content-Type': `multipart/form-data;`,
              },
            });
      
          if (!responseUpload.data.status) {
            throw responseUpload.data.message;
          }
          url_image = responseUpload.data.data.url;
        }
      const data = {
          "laporan_id":laporan.laporan.id,
          "user_id": user,
          "lokasi_id": Lokasi,
          "longitude": parseFloat(longitude),
          "latitude": parseFloat(latitude),
          "tanggal": startTime,
          "start_visit": startTime,
          "lokasi_start_visit":lokasiStartVisit,
          "end_visit": endTime,
          "lokasi_end_visit": lokasiEndVisit,
          "bukti_image":url_image,
           keterangan
      }
      EditLaporan(data)
      // console.log(data)
      notifSucces("data Berhasil Di Ubah");
      window.location = "/#/tables/Laporan-table";
    } catch (e) {
      notifWarning("data Gagal Di Ubah");
    }
  };

  console.log(dataLokasi,"Lokasi Nih")

  return (
    <Container>
      <Card variant="outlined">
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Input Manual Laporan
            </Typography>
          </Box>
        </Box>
        <CardContent sx={{ padding: "30px" }}>
          <form onSubmit={handleSubmit}>
          <Select
              id="Nama-Lokasi"
              value={kota}
              onChange={handleKotaChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Kota
              </MenuItem>
              {dataKota.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
            <Select
            disabled ={kota===''}
              id="Nama-Lokasi"
              value={Lokasi}
              onChange={handleLokasiChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Lokasi
              </MenuItem>
              {dataLokasi.map((lokasi) => (
                <MenuItem key={lokasi.id} value={lokasi.id}>
                  {lokasi.nama}
                </MenuItem>
              ))}
            </Select>

            <Select
              id="Nama User"
              value={user}
              onChange={handleUserChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select User
              </MenuItem>
              {dataUser.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.nama}
                </MenuItem>
              ))}
            </Select>

            <span>Start Time</span>
            <TextField
              id="start-time"
              type="datetime-local"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />
             <span>End Time</span>
            <TextField
              id="end-time"
              type="datetime-local"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <TextField
  id="lokasi-start-visit"
  label="Lokasi Start Visit"
  fullWidth
  value={lokasiStartVisit}
  onChange={(e) => setLokasiStartVisit(e.target.value)}
  sx={{
    mb: 2,
  }}
/>

<TextField
  id="lokasi-end-visit"
  label="Lokasi End Visit"
  fullWidth
  value={lokasiEndVisit}
  onChange={(e) => setLokasiEndVisit(e.target.value)}
  sx={{
    mb: 2,
  }}
/>

<TextField
  id="longitude"
  label="Longitude"
  fullWidth
  value={longitude}
  onChange={(e) => setLongitude(e.target.value)}
  sx={{
    mb: 2,
  }}
/>

<TextField
  id="latitude"
  label="Latitude"
  fullWidth
  value={latitude}
  onChange={(e) => setLatitude(e.target.value)}
  sx={{
    mb: 2,
  }}
/>

           <TextField
           sx={{mb:2}}
          id="standard-multiline-flexible"
          label="Keterangan"
          multiline
          fullWidth
          value={keterangan}
          onChange={(e) => setKeterangan(e.target.value)}
          variant="standard"
        />

          <input
              accept="image/*"
              id="image-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <label htmlFor="image-input">
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Uploaded"
                  style={{ maxWidth: "100%", maxHeight: "200px", marginBottom: "10px", display:"flex" }}
                />
              )}
              {!image && bukti && (
                <img
                src={bukti}
                alt="Uploaded"
                style={{ maxWidth: "100%", maxHeight: "200px", marginBottom: "10px", display:"flex" }}
              />
              )}
              <Button
                component="span"
                variant="outlined"
                color="primary"
                sx={{ mb: 2 }}
              >
                Upload Bukti
              </Button>
            </label>

            <div>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LaporanFormEdit;
