import React, { useEffect, useState } from "react";

import { Card, CardContent, Box, Typography, Button, TextField, Pagination } from "@mui/material";

import ListUserTable from "./element/UserTabel";
import { GetDataUser, CreateBulkUser } from "./userServices";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { read, utils } from "xlsx";
import { notifSucces, notifWarning } from "../../components/BaseCard/alert";


const UserTable = () => {
  const [EventData, setEventData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading,setIsLoading]= useState(false);
  const [isdelete, setIsdelete]= useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  let role = localStorage.getItem("role")??"viewer";

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedFile(null);
  };
  
  const handleUpload = async() => {
    if (!selectedFile) {
      
      handleDialogClose();
      notifWarning("Mohon Pilih File dulu");
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = async(event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = read(data, { type: "array" });
  
        // Assuming the first sheet is the one you want to read
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(sheet, { header: 1 });
        
        var dataFix = jsonData.map(item => ({ 
          "nama": item[0],
          "username": item[1],
          "password": item[2],
          "penugasan": item[3],
          "role":item[4],
          "created_by": item[5]
       })).slice(1); 
        
       const resp= await CreateBulkUser(dataFix);
        handleDialogClose();
        if(resp.status){
          notifSucces("Data Berhasil Disimpan");
        }else{
          notifWarning("Data Gagal Disimpan");
        }
        setIsdelete(!isdelete);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        notifWarning("Data Gagal Disimpan");
        handleDialogClose();
        setIsdelete(!isdelete);
      }
    };
  
    reader.readAsArrayBuffer(selectedFile);
  };
  
  
  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }

  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchEventData(search);
    }, 200);
    return () => clearTimeout(delayTimeout);
  }, [isdelete,search]);

  const fetchEventData = async (search) => {
    try {
      setIsLoading(true);
      var role1 =await localStorage.getItem("role")??"viewer";
      console.log(role1,"WKWKWK", role1 === 'approver');
      var status ='';
      if(role1 === 'approver'){
        status='Pending';
      }else if(!(role1 === 'maker'||role1 === 'super')){
        status='Kosong';
      }
      const data = await GetDataUser(search,status);

      if (data.status) {
        setEventData(data.data);
      setIsLoading(false);
      }
      else{
        setEventData([])
         setIsLoading(false);
      }
      
    } catch (error) {
      console.error("Error fetching Event data:", error);
      setIsLoading(false);
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleDeleteEvent=()=>{
    setIsdelete(!isdelete);
  }
  const handelAdd=()=>{
    window.location = '/#/form-layouts/form-event'
  }
  return (
        <Box>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Data User</Typography>
              {role !== 'approver' ? (
        <div>
                
        <Button variant="outlined" style={{ marginRight: 10 }} color="primary" onClick={handelAdd} >
          Tambah Data
        </Button>
        <Button
variant="outlined"
color="primary"
startIcon={<CloudUploadIcon />}
onClick={handleDialogOpen}
>
Upload File
</Button>
       </div>
      ) : null}
              
            </CardContent>
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            maxWidth: 300, 
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small" 
            value={search}
            onChange={handleSearchChange}
            fullWidth 
          />
        </Box>
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <ListUserTable events={EventData} loading={isLoading} onDeleteEvent={handleDeleteEvent} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Pagination
                count={totalPages} 
                page={page}
                onChange={(event, page) => setPage(page)}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Card>
          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
  <DialogTitle>Upload Excel File</DialogTitle>
  <DialogContent>
    <FormControl fullWidth>
      <InputLabel htmlFor="file-input"></InputLabel>
      <Input
        type="file"
        id="file-input"
        onChange={handleFileChange}
        accept=".xlsx, .xls"
      />
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDialogClose} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleUpload} color="primary" variant="contained">
      Upload
    </Button>
  </DialogActions>
</Dialog>
      </Box>
  );
};

export default UserTable;
