
import { Navigate } from "react-router-dom";
import AlarmTabel from "../views/tables/AlarmsTablePage.js";
import FormNews from "../views/formInput/NewsFormPage.js";
import EditNewsFormPage from "../views/formInput/NewsFormEditPage.js";
import LoginPage from "../views/auth/LoginPage.js";
import EventFormPage from "../views/formInput/EventsFormPage.js";
import EditEventFormPage from "../views/formInput/EventsFormEditPage.js";
import DiscussionFormPage from "../views/formInput/DiscussionFormPage.js";
import UserFormPage from "../views/formInput/UserFormPage.js";
import EditDiscussionFormPage from "../views/formInput/DiscussionFormEditPage.js";
import LaporanTable from "../views/tables/laporanTabelPage.js";
import RiwayatMutasiTabel from "../views/tables/riwayatMutasiPage.js";
import KotasTabel from "../views/tables/KotasTablePage.js";
import IzinTabelPage from "../views/tables/IzinTabelPage.js"
import UserTable from "../views/tables/UsersTablePage.js";
import LokasiTabel from "../views/tables/LokasiTablePage.js";
import FullLayout from "../layouts/FullLayout/FullLayout.js"
import RoleTabel from "../views/tables/roleTablePage.js";
import Menuitems from "../layouts/FullLayout/Sidebar/data.js";
import LaporanFormPageEdit from "../views/formInput/LaporanFormEditPage.js";
import LaporanFormPage from "../views/formInput/LaporanFormPage.js";

let role = localStorage.getItem("role")??"viewer";
const firstItemWithRole = Menuitems.find(item => !item.role || item.role.includes(role) || role === 'super');

const firstItemHref =  firstItemWithRole ? firstItemWithRole.href : "tables/news-table";

const ThemeRoutes = [
  {
    path: "/",
    element: !localStorage.getItem("authToken") ? <LoginPage />  :<FullLayout />,
    children: [
      { path: "/", element: <Navigate to={firstItemHref}/> },
      { path: "tables/news-table", element: <KotasTabel />},
      { path: "tables/events-table", element: <UserTable /> },
      { path: "tables/users-table", element: <AlarmTabel /> },
      { path: "tables/laporan-table", element: <LaporanTable /> },
      { path: "tables/topic-discussion-table", element: <LokasiTabel /> },
      { path: "tables/Mutasi-table", element: <RiwayatMutasiTabel /> },
      { path: "tables/Izin-table", element: <IzinTabelPage /> },
      { path: "tables/Role-table", element: <RoleTabel /> },
      { path: "/form-layouts/form-news", element: <FormNews />},
      { path: "/form-layouts/form-event", element: <EventFormPage />},
      { path: "/form-layouts/form-discus", element: <DiscussionFormPage />},
      { path: "/form-layouts/form-user", element: <UserFormPage />},
      { path: "/form-layouts/form-laporan", element: <LaporanFormPage />},
      { path: "/form-layouts/edit-form-laporan/:laporanId", element: <LaporanFormPageEdit /> },
      { path: "/form-layouts/edit-form-event/:eventId", element: <EditEventFormPage /> },
      { path: "/form-layouts/edit-form-news/:newsId", element: <EditNewsFormPage /> },
      { path: "/form-layouts/edit-form-discus/:discussionId", element: <EditDiscussionFormPage /> },
     ],
  }
];

export default ThemeRoutes;
