import React, { useEffect, useState } from "react";

import { Card, CardContent, Box, Typography, Button, Pagination, Select, MenuItem, TextField} from "@mui/material";
import * as XLSX from 'xlsx';
import IzinTabel from "./element/IzinTabel";
import { GetDataIzin } from "./izinServices";


const IzinTabelPage = () => {
  const [EventData, setEventData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading,setIsLoading]= useState(false);
  const [isdelete, setIsdelete]= useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [status,setStatus]= useState("");
  const listStatus =['pending','approve','tolak'];
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedFile(null);
  };

  const handleStatusChange =(event)=>{
   setStatus(event.target.value)
  }
  
  // const handleUpload = async() => {
  //   if (!selectedFile) {
      
  //     handleDialogClose();
  //     notifWarning("Mohon Pilih File dulu");
  //     return;
  //   }
  
  //   const reader = new FileReader();
  
  //   reader.onload = async(event) => {
  //     try {
  //       const data = new Uint8Array(event.target.result);
  //       const workbook = read(data, { type: "array" });
  
  //       // Assuming the first sheet is the one you want to read
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       const jsonData = utils.sheet_to_json(sheet, { header: 1 });
        
  //       var dataFix = jsonData.map(item => ({ 
  //         "nama": item[0],
  //         "username": item[1],
  //         "password": item[2],
  //         "penugasan": item[3],
  //         "role":item[4],
  //         "created_by": item[5]
  //      })).slice(1); 
        
  //      const resp= await CreateBulkUser(dataFix);
  //       handleDialogClose();
  //       if(resp.status){
  //         notifSucces("Data Berhasil Disimpan");
  //       }else{
  //         notifWarning("Data Gagal Disimpan");
  //       }
  //       setIsdelete(!isdelete);
  //     } catch (error) {
  //       console.error("Error reading Excel file:", error);
  //       notifWarning("Data Gagal Disimpan");
  //       handleDialogClose();
  //       setIsdelete(!isdelete);
  //     }
  //   };
  
  //   // Read the selected file as an array buffer
  //   reader.readAsArrayBuffer(selectedFile);
  // };
  
  
  const exportToExcel = () => {
    const formattedData = EventData.map(item => ({
      'Nama': item.nama || '',
      'tanggal': item.tanggal || '',
      'Jenis Izin': item.status || '',
      'status': item.status_approval || '',
      'Keterangan': item.keterangan || '',
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
  
    // Custom cell styles
    const headerCellStyle = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };
  
    const columnWidths = [
      { wch: 20 }, 
      { wch: 20 },
      { wch: 15 }, 
      { wch: 15 }, 
      { wch: 30 }, 
    ];
  
    // Apply custom styles to header row
    Object.keys(worksheet).forEach(cell => {
      if (cell[1] === '1') {
        worksheet[cell].s = headerCellStyle;
      }
    });
  
    // Apply column widths
    worksheet['!cols'] = columnWidths;
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Data');
    XLSX.writeFile(workbook, `izin-${status}-${startTime}-${endTime}.xlsx`);
  };

  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }

  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchEventData(search,status,startTime,endTime);
    }, 200);
    return () => clearTimeout(delayTimeout);
  }, [isdelete,search,status,startTime,endTime]);

  const fetchEventData = async (search,s,aw,ak) => {
    try {
      setIsLoading(true);
      const data = await GetDataIzin(search,s,aw,ak);

      if (data.status) {
        setEventData(data.data);
      setIsLoading(false);
      }
      else{
        setEventData([])
         setIsLoading(false);
      }
      
    } catch (error) {
      console.error("Error fetching Event data:", error);
      setIsLoading(false);
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleDeleteEvent=()=>{
    setIsdelete(!isdelete);
  }
  const handelAdd=()=>{
    window.location = '/#/form-layouts/form-event'
  }
  return (
        <Box>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Data Perizinan Petugas</Typography>
              <Button variant="outlined" color="primary" sx={{ paddingX: 5, paddingY: 1, marginRight: 3 }} onClick={exportToExcel}>Export</Button>
                     
            </CardContent>
            <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap:4,
            maxWidth: 800, 
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="large" 
            value={search}
            onChange={handleSearchChange}
            fullWidth 
          />
             <Select
              id="Nama-Kota"
              value={status}
              onChange={handleStatusChange}
              displayEmpty
            
              variant="outlined"
              sx={{
              }}
            >
              <MenuItem value="" disabled>
                Select Status
              </MenuItem>
              {listStatus.map((kota) => (
                <MenuItem key={kota} value={kota}>
                  {kota}
                </MenuItem>
              ))}
            </Select>
            <div>
          <span>Tanggal Awal</span>
            <TextField
              id="start-time"
              type="date"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />  
          </div>    

          <div>
          <span>Tanggal Akhir</span>
            <TextField
              disabled = {startTime === ''}
              id="endTime"
              type="date"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />  
          </div>    
       
        </Box>
            <Box
              sx={{
                overflow: {
                  xs: "auto",
                  sm: "unset",
                },
              }}
            >
              <IzinTabel events={EventData} loading={isLoading} onDeleteEvent={handleDeleteEvent} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <Pagination
                count={totalPages} 
                page={page}
                onChange={(event, page) => setPage(page)}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Card>
          
      </Box>
  );
};

export default IzinTabelPage;
