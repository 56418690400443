import React, { useState, useEffect } from "react";
import { Card, CardContent, Box, Typography, Button, TextField,Pagination } from "@mui/material";
import ListKotasTabel from "./element/KotasTable";
import { GetDataKota, CreateBulkKota } from "./kotaServices";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { read, utils } from "xlsx";
import { notifSucces, notifWarning } from "../../components/BaseCard/alert";


const KotasTabel = () => {
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isdelete, setIsdelete]= useState(false);
  const [isLoading,setIsLoading]= useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  let role = localStorage.getItem("role")??"viewer";
 
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedFile(null);
  };
  
  const handleUpload = () => {
    if (!selectedFile) {
      
      handleDialogClose();
      notifWarning("Mohon Pilih File dulu");
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = async(event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = read(data, { type: "array" });
  
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(sheet, { header: 1 });
        
        var dataFix = jsonData.map(item => ({ nama_kota: item[0] })).slice(1); 
        
        const resp= await CreateBulkKota(dataFix);
        handleDialogClose();
        if(resp.status){
          notifSucces("Data Berhasil Disimpan");
        }else{
          notifWarning("Data Gagal Disimpan");
        }
        setIsdelete(!isdelete);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        notifWarning("Data Gagal Disimpan");
        handleDialogClose();
        setIsdelete(!isdelete);
      }
    };
    reader.readAsArrayBuffer(selectedFile);
  };  

  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }

  useEffect(() => {
    setIsLoading(true);
    const delayTimeout = setTimeout(() => {
      fetchNewsData(page,search);
    }, 500);

    return () => clearTimeout(delayTimeout); 
  }, [page, search,isdelete]);

  const fetchNewsData = async (p,s) => {
    try {
      var role1 =await localStorage.getItem("role")??"viewer";
      console.log(role1,"WKWKWK", role1 === 'approver');
      var status ='';
      if(role1 === 'approver'){
        status='Pending';
      }else if(!(role1 === 'maker'||role1 === 'super')){
        status='Kosong';
      }
    
      const response = await  GetDataKota(s,status);
      
      if (response.status) {
        setNewsData(response.data);
        setIsLoading(false);
      }
      else{
         setNewsData([])
         setIsLoading(false);
      }
  
    } catch (error) {
      console.error("Error fetching news data:", error);
      setNewsData([])
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handelAdd=()=>{
    window.location = '/#/form-layouts/form-news'
  }
  const handleDeleteNews=()=>{
    setIsdelete(!isdelete);
  }

  return (
    <Box>
      <Card variant="outlined">
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Data Kota</Typography>
          {role !== 'approver' ? ( <div>
          <Button variant="outlined" style={{ marginRight: 10 }} color="primary" onClick={handelAdd} >
            Tambah Data
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={handleDialogOpen}
          >
            Upload File
          </Button>
         </div>):null}
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            maxWidth: 300,
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={search}
            onChange={handleSearchChange}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            overflow: {
              xs: "auto",
              sm: "unset",
            },
          }}
        >

          <ListKotasTabel News={newsData} onDeleteNews={handleDeleteNews} loading={isLoading}/>
        </Box>
        <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <Pagination
          count={totalPages} 
          page={page}
          onChange={(event, page) => setPage(page)}
          variant="outlined"
          color="primary"
        />
      </Box>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Upload Excel File</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel htmlFor="file-input"></InputLabel>
            <Input
              type="file"
              id="file-input"
              onChange={handleFileChange}
              accept=".xlsx, .xls"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary" variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      </Card>
    </Box>
  );
};

export default KotasTabel;
