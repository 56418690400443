import { createClient } from '@supabase/supabase-js';
import axios from 'axios';

const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);
 

const GetDataUser = async (search,status) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/get';


    const response = await axios.post(url, {'keyword':search,status},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const GetDataRole = async () => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/role/get';


    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const GetDataUserByid = async (id) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/getbyid';


    const response = await axios.post(url,{id}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const CreateUser = async (nama, username, password, penugasan,role) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/create';
    const user =  await localStorage.getItem("userData") ;

    const response = await axios.post(url, {
      nama,
      username,
      password,
      penugasan,
      created_by:user,
      role
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    });
   if(!response.data.status){
    // eslint-disable-next-line no-throw-literal
    throw "Gagal Membuat Data";
   }
    return response.data; 

  } catch (error) {
    console.error('Error while Create User data:', error.message);
    throw error; 
  }
  };
const CreateBulkUser = async (data) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/createbulk';

    const response = await axios.post(url, {users:data},{
      headers: {
        'Content-Type': 'application/json'
      }
    });
   if(!response.data.status){
    // eslint-disable-next-line no-throw-literal
    throw "Gagal Membuat Data";
   }
    return response.data; 

  } catch (error) {
    console.error('Error while Create User data:', error.message);
    throw error; 
  }
  };
  
const DeleteUser = async (id) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/user/delete';

    const response = await axios.post(url, {id},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if(!response.data.status){
       throw "GAGAL";
    }
  
  } catch (error) {
    console.error('Error updating data:', error.message);
    // Handle the error or rethrow it if needed
    throw error;
  }
  };
const EditUser = async (id, nama, username, penugasan,penugasanOld,password,role) => {
    try {
      const url = 'https://services.asset.binercloud.com/index.php/user/edit';
  
      const response = await axios.post(url, {
        id,
        nama,
        username,
        password,
        role
    },{
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if(!response.data.status){
        throw "Gagal Mengedit data"
      }
        if(penugasan!== penugasanOld){
          const url = 'https://services.asset.binercloud.com/index.php/mutasi/create';
  
          const response2 = await axios.post(url, {
            "user_id": id,
            "kota_baru": penugasan,
            "kota_lama": penugasanOld
        },{
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if(!response.data.status){
            throw "Gagal Input Mutasi data"
          }
        }
        
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };

  const ApproveUser = async (data) => {
    try {
  
      const url = 'https://services.asset.binercloud.com/index.php/user/approve';
  
  
      const response = await axios.post(url, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if(!response.data.status){
        throw "Gagal Mengganti Data";
      }
      return response.data; 
  
    } catch (error) {
      console.error('Error while fetching data:', error.message);
      throw error; 
    }
    };
  

export { GetDataUser, CreateUser, DeleteUser, EditUser, GetDataUserByid, GetDataRole, CreateBulkUser, ApproveUser};
