
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';
const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);




const GetDatalaporan = async (lokasi,user,awal,akhir,kota,status) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalaporan/get';
    const payload = {
      kota_id:kota,
      lokasi_id: lokasi,
      user_id: user,
      tanggal_awal:awal,
      tanggal_akhir:akhir,
      status
    };
    console.log(payload);

    const response = await axios.post(url,payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log("RES Lap", response)
    return response.data; 
  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};
const GetDataLaporanByid = async (id) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalaporan/getbyid';
    const payload = {
      id
    };
    console.log(payload);

    const response = await axios.post(url,payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log("RES Lap", response)
    return response.data; 
  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const DeleteLaporan = async (id) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/datalaporan/delete';


    const response = await axios.post(url, {id},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const CreateLaporan = async (data) => {
    try {
        const url = 'https://services.asset.binercloud.com/index.php/datalaporan/create';
  
  
        const response = await axios.post(url, data,{
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        return response.data; 
  
      } catch (error) {
        console.error('Error while fetching data:', error.message);
        throw error; 
      }
};
const EditLaporan = async (data) => {
    try {
        const url = 'https://services.asset.binercloud.com/index.php/datalaporan/edit';
  
  
        const response = await axios.post(url, data,{
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        return response.data; 
  
      } catch (error) {
        console.error('Error while fetching data:', error.message);
        throw error; 
      }
};

const ApproveLaporan = async (data) => {
  try {

    const url = 'https://services.asset.binercloud.com/index.php/datalaporan/approve';


    const response = await axios.post(url, data,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(!response.data.status){
      throw "Gagal Mengganti Data";
    }
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
  };


export {GetDatalaporan,DeleteLaporan, CreateLaporan,ApproveLaporan,GetDataLaporanByid,EditLaporan};
