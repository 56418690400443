import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress
} from "@mui/material";
import { ApproveUser, DeleteUser } from "../userServices";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";

  
const UserTable = ({ events , loading,onDeleteEvent}) => {
  var userRole = localStorage.getItem("role")??"viewer";

  const handleApproveClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENGAPPROVE User INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "Approve"
  }
  try{
   await ApproveUser(data);
   notifSucces("User Berhasil Di Approve");
   onDeleteEvent();
  }catch(e){
    notifWarning("User Gagal Di Approve");
  }}
  };

  const handleAjukanDeleteClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENGHAPUS User INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "Pending Hapus"
  }
  try{
   await ApproveUser(data);
   notifSucces("user Berhasil Di Ajukan Delete");
   onDeleteEvent();
  }catch(e){
    notifWarning("user Gagal Di Ajukan Delete");
  }}
  };


  const handleTolakClick = async (id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENOLAK User INI?")) {
      var data = {
        "id": parseInt(id),
        "status_approval": "Di tolak"
    }
    try{
     await ApproveUser(data);
     notifSucces("User Berhasil Di Tolak");
     onDeleteEvent();
    }catch(e){
      notifWarning("User Gagal Di Tolak");
    }
    }
  };


  const handleEditClick = (eventId) => {
    window.location=`#/form-layouts/edit-form-event/${eventId}`;
  };

  const handleDeleteClick = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this User?")) {
      try {
        await DeleteUser(eventId);
       await notifSucces("User Berhasil Di Hapus");
        onDeleteEvent();
      } catch (error) {
        console.error("Error while deleting User:", error);
        notifWarning("User Gagal Di Hapus");
      }
    }
  };
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }
  
  if (events.length === 0 || events == null || !events) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
        sx={{
           wordWrap: 'break-word', 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
          overflowX: "auto",
          maxWidth: "100%", 
        }}
      >
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              No
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              nama
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              username
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              penugasan
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
              role
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
              status
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
              aksi
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {events.map((event,index) => (
          <TableRow key={event.id}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
               {index + 1}
              </Typography>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {event.nama}
                  </Typography>
                 
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {event.username}
                  </Typography>
                 
                </Box>
              </Box>
            </TableCell>
            <TableCell>
             <Typography
                    variant="p"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontWeight: "400",
                    }}
                  >
                    {event.penugasan}
                  </Typography>
              </TableCell>

            <TableCell align="center">
              <Typography variant="h6">
              {event.role}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6">
              {event.status_approval}
              </Typography>
            </TableCell>
           
            <TableCell align="center">
                       {userRole === 'approver'  && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled = {!(event.status_approval === "Pending" || event.status_approval === "Pending Hapus")}
                      onClick={() =>  event.status_approval === "Pending Hapus"? handleDeleteClick(event.id): handleApproveClick(event.id) }
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled = {!(event.status_approval === "Pending" || event.status_approval === "Pending Hapus")}
                      sx={{ ml: 2 }}
                      onClick={() => handleTolakClick(event.id)}
                    >
                      Reject
                    </Button>
                  </>
                )}
           
           {(userRole === 'super' || userRole === 'maker' ) && ( 
           <>
           <Button
                variant="outlined"
                color="error"
                onClick={() => handleAjukanDeleteClick(event.id)}
                sx={{
                minWidth:100, mr: 2 }}
              >
                Delete
              </Button>
              <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick(event.id) }
            >
              Edit
            </Button>
            </>)}
              </TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
  );
}
};

export default UserTable;
