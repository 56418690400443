import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress
} from "@mui/material";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";
import { EditIzin } from "../izinServices";

  
const IzinTabel = ({ events , loading,onDeleteEvent}) => {
  const handleApproveClick = async(id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENGAPPROVE IZIN INI?")) {
    var data = {
      "id": parseInt(id),
      "status_approval": "approve"
  }
  try{
   await EditIzin(data);
   notifSucces("Izin Berhasil Di Approve");
   onDeleteEvent();
  }catch(e){
    notifWarning("Izin Gagal Di Approve");
  }}
  };

  const handleTolakClick = async (id) => {
    if (window.confirm("APAKAH ANDA YAKIN MENOLAK IZIN INI?")) {
      var data = {
        "id": parseInt(id),
        "status_approval": "tolak"
    }
    try{
     await EditIzin(data);
     notifSucces("Izin Berhasil Di Tolak");
     onDeleteEvent();
    }catch(e){
      notifWarning("Izin Gagal Di Tolak");
    }
    }
  };
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (events.length === 0 || events == null || !events) {
    return (
      <TableHead>
        <TableRow>
          <TableCell >
            <Typography>Data Kosong</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  } else {
    return (
      <Box
        sx={{
           wordWrap: 'break-word', 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
          overflowX: "auto",
          maxWidth: "100%", 
        }}
      >
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              No
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              nama
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
            tanggal
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
            jenis
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
            keterangan
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
            status
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="textSecondary" variant="h6">
              aksi
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {events.map((event,index) => (
          <TableRow key={event.id}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
               {index + 1}
              </Typography>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {event.nama}
                  </Typography>
                 
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {event.tanggal}
                  </Typography>
                 
                </Box>
              </Box>
            </TableCell>
            <TableCell>
             <Typography
                    variant="p"
                    sx={{
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal', 
                      fontWeight: "400",
                    }}
                  >
                    {event.status}
                  </Typography>
              </TableCell>

            <TableCell align="center">
              <Typography variant="h6">
              {event.keterangan}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6">
              {event.status_approval}
              </Typography>
            </TableCell>
           
            <TableCell align="center">
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleApproveClick(event.id)}
                disabled={event.status_approval !== "pending"}
              >
                Approve
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleTolakClick(event.id)}
                disabled={event.status_approval !== "pending"}
                sx={{ ml: 2 }}
              >
                Tolak
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
  );
}
};

export default IzinTabel;
