
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';
const supabaseUrl = 'https://iumnogjvfoudtndawjsj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml1bW5vZ2p2Zm91ZHRuZGF3anNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEzOTY4NjIsImV4cCI6MjAxNjk3Mjg2Mn0.xGAMHzMNgukeqevmVz-hiiuSUml_7rF2wQbgsQAb6q8';
const supabase = createClient(supabaseUrl, supabaseKey);




const GetDataalarmByid = async (id) => {
    try {
        const { data, error } = await supabase
        .from('alarm')
        .select()
        .eq('id',id)
        if(error){
            console.log(error)
        }

        return data;
      } catch (error) {
        console.error('Error while logging in:', error.message);
      } 
};
const GetDataalarm = async () => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/alarm/get';


    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log("RES", response)
    
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const Deletealarm = async (id) => {
    try {
        const { error } = await supabase
        .from('alarm')
        .delete()
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };

const Editalarm = async (id, nama_alarm) => {
    try {
        const { error } = await supabase
        .from('alarm')
        .update({ 
            nama_alarm,
         })
        .eq('id', id)
  
      if (error) {
        throw error;
      }
      console.log('User created successfully.');
    } catch (error) {
      console.error('Error creating user:', error.message);
      throw error;
    }
  };
  

export {GetDataalarm, GetDataalarmByid,Editalarm, Deletealarm};
