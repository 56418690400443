import React, { useEffect, useState } from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { GetDataKota } from "../../tables/kotaServices";
import L from 'leaflet'; 
import axios from "axios";
import { notifSucces, notifWarning } from "../../../components/BaseCard/alert";


import 'leaflet/dist/leaflet.css'; 
import { Createlokasi } from "../../tables/lokasiServices";


const DiscussionForm = () => {
  const [title, setTitle] = useState("");
  const [dataKota, setDataKota] = useState([]);
  const [assignment, setAssignment] = useState("");
  const [position, setPosition] = useState(null); 
  const [isLoading, setIsLoading] = useState(false); 
  const [nearestBuilding, setNearestBuilding] = useState("");
  const [image, setImage] = useState(null);
  const OPEN_CAGE_API_KEY = "10c886ceb90448e898d670a2243e1346";

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleAssignmentChange = (event) => {
    setAssignment(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const response = await Createlokasi(title,position.lat,position.lng,assignment,image,nearestBuilding);
      console.log(response);
      window.location = '/#/tables/topic-discussion-table'
      setIsLoading(false)
      notifSucces("Lokasi Berhasil Di simpan");
    } catch (error) {
      console.error("Error while adding Topic Discussion:", error);
      setIsLoading(false)
      notifWarning("Lokasi Gagal Di simpan");
    }
  };
  
  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data.data);
  };

  useEffect(() => {
    fetchKota();
},[]);
 
useEffect(() => {
  fetchKota();
  const map = L.map('map').setView([-6.900088948507798, 107.6080331521142], 18);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

  const handleMapClick = async (e) => {
    setPosition(e.latlng);
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?key=${OPEN_CAGE_API_KEY}&q=${e.latlng.lat}+${e.latlng.lng}`
      );
      const nearestBuildingName =
        response.data.results[0]?.formatted;
        console.log(nearestBuildingName);
      // Update state with the nearest building name
      setNearestBuilding(nearestBuildingName);
    } catch (error) {
      console.error("Error fetching nearest building:", error);
    }
  };

  map.on('click', handleMapClick);

  return () => {
    map.remove(); 
  };
}, []);
  
  if(isLoading){
    return <div>
      Loading
    </div>
  }

  return (
    <div>
      <Card>
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Input Lokasi
            </Typography>
          </Box>
        </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              id="title-text"
              label="Nama Lokasi"
              type="text"
              variant="outlined"
              fullWidth
              value={title}
              required
              onChange={handleTitleChange}
              sx={{
                mb: 2,
              }}
            />
            <Select
              id="Nama-Kota"
              value={assignment}
              onChange={handleAssignmentChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                mb: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select Kota
              </MenuItem>
              {dataKota.map((kota) => (
                <MenuItem key={kota.id} value={kota.id}>
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
            <div id="map" style={{ height: '500px', width: '100%' }} />
            <br></br>
            <div className="selected-coordinates">
        <label htmlFor="latitude">Latitude:</label>
        <input
          type="text"
          id="latitude"
          value={position ? position.lat : ''}
          readOnly
        />
        <label htmlFor="longitude">Longitude:</label>
        <input
          type="text"
          id="longitude"
          value={position ? position.lng : ''}
          readOnly
        />
        
      </div>
      <br></br>
          <div>
          <label htmlFor="nearestBuilding">Nearest Building:</label>
          <input
            type="text"
            id="nearestBuilding"
            value={nearestBuilding}
            readOnly
            style={{ width: '50%', padding: '8px' }} 
          />
        </div>
          <br></br>
          <input
              accept="image/*"
              id="image-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <label htmlFor="image-input">
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Uploaded"
                  style={{ maxWidth: "100%", maxHeight: "200px", marginBottom: "10px", display:"flex" }}
                />
              )}
              <Button
                component="span"
                variant="outlined"
                color="primary"
                sx={{ mb: 2 }}
              >
                Upload Image
              </Button>
            </label>
            <div>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </form>
          </Card>
    </div>
  );
};

export default DiscussionForm;
