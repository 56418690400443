
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';





const GetDataKotaByid = async (id) => {
  try {

    const url = 'https://services.asset.binercloud.com/index.php/kota/getbyid';


    const response = await axios.post(url, {id},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};
const GetDataKota = async (keyword, status) => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/kota/get';


    const response = await axios.post(url, {keyword,status},{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
};

const CreateKota = async (nama_kota) => {
  try {
      const url = 'https://services.asset.binercloud.com/index.php/kota/create';


      const response = await axios.post(url, {nama_kota},{
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if(!response.data.status){
        throw "Gagal Menambah Data";
      }
      return response.data; 

    } catch (error) {
      console.error('Error while fetching data:', error.message);
      throw error; 
    }
  };

  const CreateBulkKota = async (data) => {
    try {
        const url = 'https://services.asset.binercloud.com/index.php/kota/createbulk';
  
  
        const response = await axios.post(url, {data_kota:data},{
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        return response.data; 
  
      } catch (error) {
        console.error('Error while fetching data:', error.message);
        throw error; 
      }
    };

const DeleteKota = async (id) => {
  try {

      const url = 'https://services.asset.binercloud.com/index.php/kota/delete';


      const response = await axios.post(url, {id},{
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data; 

    } catch (error) {
      console.error('Error while fetching data:', error.message);
      throw error; 
    }
  };

const EditKota = async (id, nama_kota) => {
  try {

    const url = 'https://services.asset.binercloud.com/index.php/kota/edit';


    const response = await axios.post(url, {id,nama_kota},{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(!response.data.status){
      throw "Gagal Mengganti Data";
    }
    return response.data; 

  } catch (error) {
    console.error('Error while fetching data:', error.message);
    throw error; 
  }
  };
  
  const ApproveKota = async (data) => {
    try {
  
      const url = 'https://services.asset.binercloud.com/index.php/kota/approve';
  
  
      const response = await axios.post(url, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if(!response.data.status){
        throw "Gagal Mengganti Data";
      }
      return response.data; 
  
    } catch (error) {
      console.error('Error while fetching data:', error.message);
      throw error; 
    }
    };

export {GetDataKota, GetDataKotaByid, CreateKota,EditKota, DeleteKota,CreateBulkKota, ApproveKota};
