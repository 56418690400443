import axios from 'axios';





const GetDataMutasi = async () => {
  try {
    const url = 'https://services.asset.binercloud.com/index.php/mutasi/get';
  
          const response = await axios.post(url,{
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if(!response.data.status){
            throw "Gagal Input Mutasi data"
          }

          return response.data
      
  } catch (error) {
    console.error('Error while fetching mutasi data:', error.message);
    return null; // Handle the error appropriately
  }
};


  

export {GetDataMutasi};
