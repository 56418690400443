import React, { useEffect, useState } from "react";
import { Card, CardContent, Box, Typography, Button, Pagination, Select, MenuItem, TextField } from "@mui/material";
import * as XLSX from 'xlsx';
import ListLaporanTable from "./element/laporantabel.js";
import { GetDatalaporan } from "./laporanServices.js";
import { GetDatalokasi, GetDatalokasiAll } from "./lokasiServices.js";
import { GetDataUser } from "./userServices.js";
import { GetDataKota } from "./kotaServices.js";

const LaporanTable = () => {
  const [laporan, setlaporan] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [isLoading,setIsLoading]= useState(false);
  const [isdelete, setIsdelete]= useState(false);
  const [dataLokasi, setDataLokasi] = useState([]);
  const [Lokasi, setLokasi] = useState("");
  const [dataKota, setDataKota] = useState([]);
  const [Kota, setKota] = useState("");
  const [dataUser, setDataUser] = useState([]);
  const [user, setUser] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
 
  let role = localStorage.getItem("role")??"viewer";
  
  

  const exportToExcel = () => {
    const formattedData = laporan.map(item => ({
      'Nama Petugas': item.nama || '',
      'Kota':item.nama_kota,
      'Lokasi': item.nama_lokasi || '',
      'status': item.status_approval || '',
      'Longitude': item.longitude || '',
      'Latitude': item.latitude || '',
      'Link Bukti': item.bukti_image || '',
      'Tanggal': item.tanggal||'',
      'Jam Mulai': item.start_visit.split(' ')[1].slice(0, 5) || '',
      'Jam Selesai': item.end_visit.split(' ')[1].slice(0, 5) || '',
      'lokasi Start visit':item.lokasi_start_visit,
      'lokasi End visit':item.lokasi_end_visit,
      'Keterangan': item.keterangan || '',
      'metode_input': item.metode_input
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
  
    // Custom cell styles
    const headerCellStyle = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };
  
    const columnWidths = [
      { wch: 20 }, 
      { wch: 20 },
      { wch: 20 },
      { wch: 15 }, 
      { wch: 15 }, 
      { wch: 15 }, 
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 40 }, 
      { wch: 40 }, 
      { wch: 30 }, 
      { wch: 20 } 
    ];
  
    // Apply custom styles to header row
    Object.keys(worksheet).forEach(cell => {
      if (cell[1] === '1') {
        worksheet[cell].s = headerCellStyle;
      }
    });
  
    // Apply column widths
    worksheet['!cols'] = columnWidths;
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Data');
    XLSX.writeFile(workbook, 'laporan_data.xlsx');
  };
  
  const fetchKota = async () => {
    const data = await GetDataKota();
    setDataKota(data.data);;
  };
  const fetchUser = async () => {
    const data = await GetDataUser();
    setDataUser(data.data);
    console.log(data);
  };

  useEffect(() => {
    fetchUser();
    fetchKota();
},[]);

const handelAdd=()=>{
  window.location = '/#/form-layouts/form-laporan'
}
const handleLokasiChange = (event) => {
  setLokasi(event.target.value);
};
const handleKotaChange = async(event) => {
  setKota(event.target.value);
  const data = await GetDatalokasi(event.target.value);
  setDataLokasi(data?.data??[]);
  
};
const handleUserChange = (event) => {
  setUser(event.target.value);
};
  if(!localStorage.getItem("authToken")){
    window.location='/login'
  }
  useEffect(() => {
    setIsLoading(true);
      fetchDiscusData(Lokasi,user,startTime,endTime,Kota);  
  }, [isdelete, user,Lokasi, startTime,endTime, Kota]);

  const fetchDiscusData= async (l,u,s,e,k) => {
    try {
      var role1 =await localStorage.getItem("role")??"viewer";
      console.log(role1,"WKWKWK", role1 === 'approver');
      var status ='';
      if(role1 === 'supervisor'){
        status='Pending';
      }else if(role1 === 'viewer'){
        status="approve"
      }else if(!(role1 === 'admin'||role1 === 'super'||role1 === 'inputter')){
        status='Kosong';
      }
      const response = await  GetDatalaporan(l,u,s,e,k,status);
      if(response.status){
      setlaporan(response.data);
      setIsLoading(false);
      }
      else{
        setlaporan([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching laporan data:", error);
      setIsLoading(false);
    }
  };
  const handleDeleteDiscus=()=>{
    setIsdelete(!isdelete);
  }
  return (
    <Box>
    <Card variant="outlined">
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">Data Laporan</Typography>
        {(role === 'inputter' || role === 'super') && (
        <Button variant="outlined" style={{ marginRight: 10 }} color="primary" onClick={handelAdd}>
          Tambah Data
        </Button>
      )}
      </CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* Select pertama */}
          <Select
            id="Nama-Lokasi"
            value={Kota}
            onChange={handleKotaChange}
            displayEmpty
            fullWidth
            variant="outlined"
            sx={{
              mb: 2,
              mt:2
            }}
          >
            <MenuItem value="" disabled>
              Select Kota
            </MenuItem>
            {dataKota.map((kota) => (
              <MenuItem key={kota.id} value={kota.id}>
                {kota.nama_kota}
              </MenuItem>
            ))}
          </Select>

          <Select
            id="Nama-Lokasi"
            value={Lokasi}
            onChange={handleLokasiChange}
            displayEmpty
            fullWidth
            disabled = {Kota === ''}
            variant="outlined"
            sx={{
              mb: 2,
              mt:2
            }}
          >
            <MenuItem value="" disabled>
              Select Lokasi
            </MenuItem>
            {dataLokasi.map((kota) => (
              <MenuItem key={kota.id} value={kota.id}>
                {kota.nama}
              </MenuItem>
            ))}
            </Select>
          {/* Select kedua */}
          <Select
            id="Nama-Kota"
            value={user}
            onChange={handleUserChange}
            displayEmpty
            fullWidth
            variant="outlined"
            sx={{
              mb: 2,
              mt:2
            }}
          >
            <MenuItem value="" disabled>
              Select User
            </MenuItem>
            {dataUser.map((data) => (
              <MenuItem key={data.id} value={data.id}>
                {data.nama}
              </MenuItem>
            ))}
          </Select>
          <div>
          <span>Tanggal Awal</span>
            <TextField
              id="start-time"
              type="date"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />  
          </div>    

          <div>
          <span>Tanggal Akhir</span>
            <TextField
              disabled = {startTime === ''}
              id="endTime"
              type="date"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />  
          </div>    
          
            </Box>
            {role !== 'viewer' &&(<div>
          <Button variant="outlined" color="primary" sx={{ paddingX: 5, paddingY: 1, marginRight: 3 }} onClick={exportToExcel}>Export</Button>
        </div>)}
      </Box>

      <Box
        sx={{
          overflow: {
            xs: "auto",
            sm: "unset",
          },
        }}
      >
        <ListLaporanTable laporans={laporan} loading={isLoading} onDeleteReport={handleDeleteDiscus} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, page) => setPage(page)}
          variant="outlined"
          color="primary"
        />
      </Box>
    </Card>
  </Box>
  );
};

export default LaporanTable;
